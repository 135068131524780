import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core'
import { AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '18px',
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'rgb(115, 115, 115)'
  },
  details: {
    display: 'block'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: '100%',
    color: 'rgb(115, 115, 115)'
  },
  text: {
    fontSize: '100%',
    fontWeight: 'bold',
    color: 'rgb(115, 115, 115)'
  }
}));

export default function ControlledExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{props.header1}</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Typography className={classes.text} gutterBottom>
            {props.text1 ? props.text1 : "Not available"}
          </Typography>
          <List dense="false">
            {props.list.map(item => {
                return <ListItem>
                  <ListItemIcon>
                    <Icon fontSize="small">{item.icon != null ? item.icon: null}</Icon>
                  </ListItemIcon>
                  <ListItemText className={classes.text}>
                    {!item.type ? 
                      (<div>
                        <Typography component="span" className={classes.title} gutterBottom>
                          {item.title ? item.title : "Unknown"}
                        </Typography>
                        <Typography component="span" className={classes.text} gutterBottom>
                          {item.label ? item.label : "Not available"}
                        </Typography>
                      </div>
                      ):
                      (<div>
                        <Typography component="span"  className={classes.title} gutterBottom>
                          {item.title ? item.title : "Not Unknown"}
                        </Typography>
                        <Chip component="span" label={item.label ? item.label : "Not available"} size="small" 
                        style={item.type==="negative" ? ({backgroundColor: "#FFBF00", color: "#fff" }):({backgroundColor: "#228B22", color: "#fff"})}/>
                      </div>)
                    }
                  </ListItemText>

                </ListItem>
              }
            )}
            </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>{props.header2}</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.text}>
            {props.text2 ? props.text2 : "Not available"}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
