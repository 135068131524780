import React from 'react';
import fetch from 'cross-fetch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import { showJoinDlg } from "../../Redux/Actions";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import capitalize from 'capitalize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReCAPTCHA from 'react-google-recaptcha';
import FormHelperText from '@material-ui/core/FormHelperText';
import {strengthIndicator, strengthText, validURL, validateEmail} from '../../Functions/Global.js';
import EmailIcon from '@material-ui/icons/Email';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  switch: {
    display: 'inherit'
  },
  switchLink: {
    cursor: 'pointer'
  },
}));

const mapStateToProps = state => {
  return { openDialogue: state.showJoinDialog };
};

const CHPROXY_URL = process.env.REACT_APP_FUNCTIONS_CHPROXY_ENDPOINT + '?items=5&query='

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function JoinDialog(props) 
{
  const { openDialogue } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [optionsData, setOptionsData] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const loading = open && options.length === 0;
  const [companyType, setCompanyType] = React.useState('Sole Proprietor');
  const [joining, setJoining] = React.useState(false);
  const [joined, setJoined] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [valid, setValid] = React.useState(false);
  const [formErrorMessage, setFormErrorMessage ] = React.useState('Please enter your first name');
  const [recaptchaValue, setRecaptchaValue] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const strength = strengthIndicator(password);
  const strengthTxt = strengthText(strength);
  const [tsandCs, setTsandCs] = React.useState(false);
  const [openTsandCs, setOpenTsandCs] = React.useState(false);
  const [scrollTsandCs, setScrollTsandCs] = React.useState('paper');
  const [tsandCsAccept, setTsandCsAccept] = React.useState(true);

  const classes = useStyles();

  const handleClickTsandCsOpen = (scrollType) => () => {
    setOpenTsandCs(true);
    setScrollTsandCs(scrollType);
  };

  const handleTsandCsClose = () => {
    setOpenTsandCs(false);
  };

  const handleTsandCsScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) 
      setTsandCsAccept(false);
    else
      setTsandCsAccept(true);
  }

  const handleCompanyTypeChange = (event) => 
  {
    setCompanyType(event.target.value);
  };

  const validateForm = () =>  
  {
    const errors = {};
    let validData = 
    {
      formErrorMessage: '',
      valid: true
    }
   
    if (firstName.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your first name';
      validData.valid = false;

      return validData;
    }
    if (lastName.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your last name';
      validData.valid = false;

      return validData;
    }
    if (company.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your company';
      validData.valid = false;

      return validData;
    }
    if (website != null && website.length > 0)
    {
        if (!validURL(website))
        {
          validData.formErrorMessage = 'Please enter a valid website';
          validData.valid = false;
    
          return validData;
        }
    }

    if (email.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your email';
      validData.valid = false;

      return validData;
    }
    if (validateEmail(email)) 
    {
      validData.formErrorMessage = 'Please enter a valid email';
      validData.valid = false;

      return validData;
    }
    if (phone.length < 6) 
    {
      validData.formErrorMessage = 'Please enter your phone number';
      validData.valid = false;

      return validData;
    }
    if (password.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your password';
      validData.valid = false;

      return validData;
    }
    if (strength < 4)
    {
      validData.formErrorMessage = 'Your password needs to be stronger';
      validData.valid = false;

      return validData;
    }
    if (confirmPassword.length < 3) 
    {
      validData.formErrorMessage = 'Please enter your confirm password';
      validData.valid = false;

      return validData;
    }
    if (password !== confirmPassword) 
    {
      validData.formErrorMessage = 'Your confirm password must match your password';
      validData.valid = false;

      return validData;
    }
    if (recaptchaValue == null) 
    {
      validData.formErrorMessage = 'Please tick the reCaptcha';
      validData.valid = false;

      return validData;
    }
    if (!tsandCs) 
    {
      validData.formErrorMessage = 'Please accept the terms and conditions';
      validData.valid = false;

      return validData;
    }

    return validData;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleJoinSubmit = (event) => 
  {  
    event.preventDefault();
    setJoining(true);

    // lookup company
    let companyData = optionsData.find(_company => _company.companyName === company.toUpperCase());

    let joinData = 
    {
      firstName: firstName,
      lastName: lastName,
      company: company,
      companyId: companyData !== undefined && companyData.companyId !== null ? companyData.companyId:null,
      companyType: companyType,
      branch: branch,
      website: website,
      email: email,
      phone: phone,
      password: password,
      confirmPassword: confirmPassword,
      recaptchaValue: recaptchaValue
    }

    fetch(process.env.REACT_APP_FUNCTIONS_JOINUSER_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(joinData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setJoining(false);
          setJoined(true);
        },
        (error) => {
          setJoining(false);
          setJoined(false);
          setSnackbarOpen(true);
          setSnackbarMessage("We have encountered a problem joining you right now");
      });
  };

  React.useEffect(function afterRender() {
      let validData = validateForm();
      console.log("message " + validData.valid + ", " + validData.formErrorMessage);
      setValid(validData.valid);
      setFormErrorMessage(validData.formErrorMessage);
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try 
      {
          const response = await fetch(CHPROXY_URL + inputValue);
          const companies = await response.json();

          if (active) {
            setOptions(Object.keys(companies.items)
              .map((key) => ( {name: capitalize.words(companies.items[key].title), value: companies.items[key].title } )));
            setOptionsData(Object.keys(companies.items)
              .map((key) => ( {companyName: companies.items[key].title, companyId: companies.items[key].company_number } )));
          }
      } 
      catch (err) 
      {
        console.error("Problem accessing chproxy " + err);
      }    
      })();

    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  return (
    !joined ? (
      <div>
      <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        open={openDialogue} 
        onClose={() => {props.dispatch(showJoinDlg(false))}} aria-labelledby="join-form">
          <DialogTitle id="form-dialog-title">Join AllBees</DialogTitle>
          <form onSubmit={handleJoinSubmit} autoComplete="off">
          <DialogContent>
            <DialogContentText>
              Fill in the form below to create an AllBees Distributor Account
            </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    required
                    margin="normal"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    onChange={(e) => {setFirstName(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    onChange={(e) => {setLastName(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (newInputValue.trim() === '' || newInputValue.length < 4)
                      {
                        setOpen(false)
                        setOptions([]);
                      }
                      else
                      {
                        setOpen(true);
                        setOptions([]);
                        setInputValue(newInputValue);
                        setCompany(newInputValue); 
                      }
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    autoComplete
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Company"
                        id="company"
                        name="company"
                        margin="normal"
                        helperText="Type to find company"
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }} 
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl 
                    fullWidth
                  >
                    <InputLabel id="companyType">Company Status</InputLabel>
                    <Select
                      labelId="companyType-label"
                      id="companyType"
                      name="companyType"
                      value={companyType}
                      onChange={handleCompanyTypeChange}
                    >
                      <MenuItem value={'Sole Proprietor'}>Sole Proprietor</MenuItem>
                      <MenuItem value={'Partnership'}>Partnership</MenuItem>
                      <MenuItem value={'Limited'}>Limited</MenuItem>
                      <MenuItem value={'PLC'}>PLC</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    id="branchName"
                    name="branchName"
                    label="Branch Name"
                    fullWidth
                    onChange={(e) => {setBranch(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    id="url"
                    name="url"
                    label="Your website"
                    fullWidth
                    onChange={(e) => {setWebsite(e.target.value);}} 
                    InputProps={{
                      startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    onChange={(e) => {setEmail(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="email"
                    fullWidth
                    onChange={(e) => {setPhone(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    id="password"
                    password="password"
                    label="Password"
                    type="password"
                    fullWidth
                    error={strength < 5}
                    helperText={strengthTxt}
                    onChange={(e) => { setPassword(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    onChange={(e) => { setConfirmPassword(e.target.value);}} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth="true" className={classes.switch}>
                    <Switch color="primary" onChange={(e) => { setTsandCs(e.target.checked);}} component="span" checked={tsandCs}/>
                    <Link href="" onClick={handleClickTsandCsOpen('paper')} color="primary" component="span" className={classes.switchLink}>
                      I agree to the Terms and Conditions
                    </Link>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                <FormControl>
                    <ReCAPTCHA
                      sitekey="6LeQhfcZAAAAAP-ODT4viL4jCQw4lL297ZkARFoM"
                      onChange={(value) => {
                        setRecaptchaValue(value);
                        let joinData = {
                          recaptchaValue: value
                        };
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText error={formErrorMessage !== null} id="my-helper-text">{formErrorMessage}</FormHelperText>
                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {props.dispatch(showJoinDlg(false))}} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={joining || !valid}>
              Join
            </Button>
            <Snackbar open={snackbarOpen} 
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}>
            <Alert severity="error" onClose={handleSnackbarClose}>
              {snackbarMessage} 
            </Alert>
          </Snackbar>
          </DialogActions>
          </form>
      </Dialog>
      <Dialog
      open={openTsandCs}
      onClose={handleTsandCsClose}
      scroll={scrollTsandCs}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
      <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
      <DialogContent dividers={scrollTsandCs === 'paper'} onScroll={handleTsandCsScroll}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          <h2>Terms &amp; Conditions</h2>
          <h3>TERMS AND CONDITIONS OF SALE</h3>

          <h4>INTRODUCTION</h4>
          <p>
          I . In these conditions "The Company" shall mean All Bees Ltd. 'The Buyer" shall mean the person, firm or Company to whom the Company agrees to sell or supply the goods.

          2.All contracts of sale of goods incorporate these conditions so far as such conditions are not varied by any special terms or conditions agreed in writing between the parties. Any terms and conditions of the Buyer which are inconsistent with these conditions shall have no effect. Any variation of the contract will become binding only if confirmed in writing by the Company.
          </p>
          
          <h4>RISK</h4>
          <p>
          3.Risk shall pass to the Buyer when the goods are delivered to the premises of the Buyer or to a delivery address nominated by the Buyer. Provided nevertheless that from the time of dispatch thereof from the sellers premises and until such delivery the risk of any loss or damage to or deterioration of the said goods from whatsoever cause arising shall be borne by the Buyer
          </p>
          <h4>RESERVATION OF TITLE</h4>
          <p>
          4.Title in the goods shall pass to the Buyer only when payment in full has been received by the Company for all goods whatsoever supplied (and all services rendered) at any time by the Company to the Buyer. The Buyer shall permit the servants or agents of the Company to enter onto the Buyers premises and repossess the goods at any time prior thereto.

          5.Subject to (7) below. The Buyer shall be at liberty to resell the goods in the ordinary course of business prior to the passing of title on the basis that the proceeds of sale belong to the Company to whom the Buyer will account.

          6.The Buyer's power of sale shall automatically cease if a Receiver is appointed over any of the assets, or the undertaking of the Buyer or a winding up order is made against the Buyer or the Buyer goes into voluntary liquidation (otherwise than for the purpose of reconstruction or amalgamation) or cause a meeting of or makes any arrangement or composition with creditors or commits any act of bankruptcy or allows execution to be levied against its or his goods.

          7.Should the goods or any of them be converted into a new product, whether or not such conversion involves the admixture of any other goods or thing whatsoever and in whatever proportion s the conversion shall be deemed to have been effected on behalf of the Company and the Company shall have full legal and beneficial ownership of the new products but without accepting any liability whatsoever in respect of such converted goods in relation to any third party, and the Buyer hereby indemnifies the Company in relation thereto.
          </p>

          <h4>DELIVERY</h4>
          <p>
          8.(i) Delivery otherwise stated all goods for delivery within the mainland of Great Britain will be dispatched carriage paid by the conveyance of the Company's choice Where the Buyer requires a different means of conveyance any extra cost must be paid by the Buyer.

          9. The Buyer must carefully examine all goods immediately upon their arrival. In the event of damage to the goods in transit or any short delivery written notification thereof with the claim must reach the Company and the carrier within three days of receipt of the goods (such notification not to be on the carrier's own documents). In the event of non delivery such notification and claim must reach the Company within I 0 days of the date of invoice. In the event of a failure to comply with the above requirements claims in respect of damage in transit, short delivery or non-delivery etc, will not be accepted by the company and in no event whatsoever will the Company be liable to the Buyer in respect of such loss or damage beyond the amount which it has been able to recover from its insurers, the carrier or the third parties.

          I0 . The above clause', shall not be interpreted in any way as giving right to the Buyer to return to the Company any goods other than those agreed to by the Company.
          </p>
          
          <h4>LIEN</h4>
          <p>
          11.(i) Notwithstanding that credit has or may be given for the payment of the price of goods referred to in the invoice annexed or attached hereto, the seller shall be entitled to retain possession hereof until payment. 12.In addition to any right of lien to which the seller may by law be entitled the seller (in the event of the Buyers insolvency) be entitled to a general lien on all goods of the Buyers in the sellers possession (although such goods or some of them may have been paid for) for the unpaid price of any other goods sold and delivered to the Buyer by the Company under the same or any other contract.
          </p>
          
          <h4>PRICES</h4>
          <p>
          13.Prices are subject to variation by the company without notice. Goods wi ll be invoiced at price ruling at date of dispatch.
          </p>
          
          <h4>RETURN OF STOCKS HANDLING CHARGE</h4>
          <p>
          14.If the Buyer requests the Company to take back goods sold by the Company to the Buyer, and if the Company agrees to take back such goods, then the Company will issue to the Buyer a credit note to the value of these goods including VAT less a handling charge of 20% of the net value of the goods plus VAT on such handling charge.
          </p>
          
          <h4>PAYMENT BY CHEQUE</h4>
          <p>
          15.The Buyer shall be responsible to the Company for payment of any bank charges incurred by the Company with its Bankers for representation on one or more occasions of any cheques of the Buyer which are not met when presented for payment.
          </p>
          
          <h4>LIMITED LIABILTY</h4>
          
          <p>
          16.All Bees Ltd liability shall be limited to the value of the goods sold ex-works. In no event shall All Bees Ltd be liable for any consequential damages caused by the use of its products, the application thereof, misuse, use against the recommendations of the manufacturer .

          I have read and understood the terms and conditions of sale of All Bees Ltd. I accept and agree to these terms and also the undersigned take personal responsibility for and guarantee any debt incurred by our company.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => { setTsandCs(false); handleTsandCsClose(e)} } color="primary">
          Cancel
        </Button>
        <Button onClick={(e) => { setTsandCs(true); handleTsandCsClose(e)} } color="primary" disabled={tsandCsAccept}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
    </div>
    ):
    (<Dialog 
      disableEscapeKeyDown
      open={openDialogue} 
      onClose={() => {setJoined(false); props.dispatch(showJoinDlg(false))}} aria-labelledby="join-form">
        <DialogTitle id="form-dialog-title">Welcome to AllBees</DialogTitle>
        <DialogContent>
            <DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <EmailIcon fontSize="large"/>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" component="span">
                    Please verify your email address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    We have sent a verification email to you at {email}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {setJoined(false); props.dispatch(showJoinDlg(false))}} color="primary">
              Ok
            </Button>
          </DialogActions>
      </Dialog>)
  );
}
const _JoinDialog = withRouter(connect(mapStateToProps)(JoinDialog));
export default (_JoinDialog);