import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Icon from "@material-ui/core/Icon";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import {toggleMenu} from "../../Redux/Actions";
import { connect } from "react-redux";
import { gql } from 'apollo-boost';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from '@apollo/react-hooks';
import { useQueryParam, StringParam} from 'use-query-params';
import Alert from '../CustomizedComponents/Alert'

const mapStateToProps = state => {
  return {
    menuOpen: state.showMenu,
    menuCategory: state.menuCategory,
    menuSubCategory: state.menuSubCategory
  };
};

const useStyles = theme => ({
  listItemContainer: {
      backgroundColor: 'none'
    }
});

const CATEGORIES = gql`
    query Categories {
      categories {
        label
        id
        icon
        type
        subCategories {
          label
          id
        }
      }
    }   
`;

// Menu component
function SubMenu({props, menuOpen, onCategoryChange, onSubCategoryChange, menuData, selectedIndex, selection}) { 

  const [expandedMenuItems, setExpandedMenuItems] = React.useState({20:true});
  const [previousSelection, setPreviousSelection] = React.useState({category: 0, subCategory: null});

  if (selection !== undefined && selection !== previousSelection) 
  {
    console.log("SELECTION " + selection.category + ", " + selection.subCategory + "index " + selectedIndex)
    if (selection.category !== previousSelection.category)
      setExpandedMenuItems({[selection.category]: true})
    setPreviousSelection(selection);
  }

  return (
    <List>
    {menuData
      .map((x, i) => {
        if (!x.subCategories) {
          return (
            // Categories 
            <ListItem dense button 
              selected={selectedIndex === x.id}
              onClick={() => { 
                x.type === 'generic' ? onCategoryChange(x):onSubCategoryChange(x)
                if (menuOpen)
                  props.dispatch(toggleMenu());
              }}>         
                <ListItemIcon>
                  <Icon>{x.icon != null ? x.icon: null}</Icon>
                </ListItemIcon> 
                <ListItemText
                  primary={x.type === 'generic' ? 
                  <div style={{fontSize: "110%" }} >{x.label}</div> : <div style={{fontSize: "100%" }} >{x.label}</div>}/>
            </ListItem>
            )}
        // Top Categories
        else {
          return (
            <Fragment key={x.id}>
              <Divider  variant="middle" />
              <ListItem button dense
              selected={selectedIndex === x.id}
              onClick={() => {
                onCategoryChange(x)
                setExpandedMenuItems({[x.id]: !expandedMenuItems[x.id]})
              }}
              >
              <ListItemIcon>
                <Icon>{x.icon != null ? x.icon: null}</Icon>
              </ListItemIcon>
              <ListItemText primary={<div style={{fontSize: "110%" }}>{x.label}</div>}/>
                {expandedMenuItems[x.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={expandedMenuItems[x.id]} timeout="auto" unmountOnExit>
                <SubMenu menuData={x.subCategories}  selectedIndex={selectedIndex}
                  onSubCategoryChange={onSubCategoryChange} onCategoryChange={onCategoryChange}/>
              </Collapse>
            </Fragment>
          );
        }

      })}
  </List >)
}

function Menu(props) 
{

  // eslint-disable-next-line
  const [category, setCategory] = useQueryParam('category', StringParam);
  const [categorySaved, setCategorySaved] = React.useState(null);
  // eslint-disable-next-line
  const [subCategory, setSubCategory] = useQueryParam('subCategory', StringParam);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { loading, error, data } = useQuery(CATEGORIES, {
    variables: {  },
  });

  const subCategoryChange = (subCategory) => {
    setSelectedIndex(subCategory.id);
    props.history.push("/");
    setCategory(categorySaved, 'pushIn');
    setSubCategory(subCategory.label, 'pushIn');
  };

  const categoryChange = (category) => {
    setSelectedIndex(category.id);
    setCategorySaved(category.label);   
    props.history.push("/");
    if (category.label !== 'Home')
      setCategory(category.label, 'push');
  };

  if (loading) return <CircularProgress className="circular" />;
  if (error || data.categories == null) return <Alert severity="error">Sorry we had an issue.</Alert>;

  const categoryNames = data.categories.map(category => category.label);

  if (category !== undefined)
  {
    var selectedCategory = data.categories[categoryNames.indexOf(category)] ? data.categories[categoryNames.indexOf(category)].id:undefined;
    var subCategoryNames = data.categories[categoryNames.indexOf(category)] ? data.categories[categoryNames.indexOf(category)].subCategories ? data.categories[categoryNames.indexOf(category)].subCategories.map(subCategory => subCategory.label):undefined:undefined;

    if (subCategory !== undefined && subCategoryNames !== undefined)
      var selectedSubCategory = data.categories[categoryNames.indexOf(category)].subCategories[subCategoryNames.indexOf(subCategory)] ? data.categories[categoryNames.indexOf(category)].subCategories[subCategoryNames.indexOf(subCategory)].id:undefined;

    var selection = {
                    category: selectedCategory ? selectedCategory:null,
                    subCategory: selectedSubCategory ? selectedSubCategory:null
    }

    var selected = selectedCategory ? selectedCategory:selectedIndex;
    selected = selectedSubCategory ? selectedSubCategory:selected;
  }

  return (
        <div>
          <SubMenu menuData={data.categories} selectedIndex={selected ? selected:selectedIndex} props={props} menuOpen={props.menuOpen} selection={selection}
          onSubCategoryChange={subCategoryChange} onCategoryChange={categoryChange}/>
        </div>
      );
  }
const _Menu = withRouter(connect(mapStateToProps)(Menu));
export default withStyles(useStyles)(_Menu);
