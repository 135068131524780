import React from "react";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addItemInCart, showSnackbarMessage } from "../../Redux/Actions";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import StarsIcon from '@material-ui/icons/Stars';
import ControlledExpansionPanels from '../CustomizedComponents/ControlledExpansionPanels'
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { withRouter } from "react-router-dom";
import Item from "../Item/Item";
import Alert from '../CustomizedComponents/Alert'
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useQueryParam, StringParam} from 'use-query-params';
const capitalize = require('capitalize');

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    loggedInUser: state.loggedInUser
  };
};

const PRODUCT_DETAILS = gql`
    query Product($id: ID) {
      product(id: $id) {
        id
        availability
        brand
        colour
        descrition
        discontinued
        identifierExists
        popular
        price
        merchantPrice
        productCategory
        productSubCategory
        productCode
        productImageThumbnails
        relevanceScore
        title
        unitMeasure
        unitSize
        size
      }
    }
`;

const PRODUCT_LIMITED_DETAILS = gql`
    query Product($id: ID) {
      product(id: $id) {
        id
        availability
        brand
        colour
        descrition
        discontinued
        identifierExists
        popular
        productCategory
        productSubCategory
        productCode
        productImageThumbnails
        relevanceScore
        title
        unitMeasure
        unitSize
        size
      }
    }
`;

const PRODUCT_SEARCH = gql`
    query ProductSearch($query: String, $category: String, $subCategory: String, $page: Int, $itemsPerPage: Int, $sortValue: String) {
      productSearch(query: $query, category: $category, subCategory: $subCategory, page: $page, itemsPerPage: $itemsPerPage, sortValue: $sortValue)
      {
        products
        {
          id
          availability
          brand
          descrition
          discontinued
          identifierExists
          popular
          price
          merchantPrice
          productCategory
          productSubCategory
          productCode
          productImageThumbnails
          relevanceScore
          title
          unitMeasure
          unitSize
        }
        page
        nbHits
      }
    }   
`;

const PRODUCT_LIMITED_SEARCH = gql`
    query ProductSearch($query: String, $category: String, $subCategory: String, $page: Int, $itemsPerPage: Int, $sortValue: String) {
      productSearch(query: $query, category: $category, subCategory: $subCategory, page: $page, itemsPerPage: $itemsPerPage, sortValue: $sortValue)
      {
        products
        {
          id
          availability
          brand
          descrition
          discontinued
          identifierExists
          popular
          productCategory
          productSubCategory
          productCode
          productImageThumbnails
          relevanceScore
          title
          unitMeasure
          unitSize
        }
        page
        nbHits
      }
    }   
`;

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function ProductDetails(props) 
{
  let id = props.match.params.id;
  const { isAuthenticated, loggedInUser } = props;
  const merchantPrice = loggedInUser !== null ? loggedInUser.appliedPriceSchedule !== null ? true:false : false;
  let PRODUCT_QUERY = !isAuthenticated ? PRODUCT_LIMITED_DETAILS:PRODUCT_DETAILS;

  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: { id },
  });
  const [quantity, setQuantity] = React.useState(1);
  // eslint-disable-next-line
  const [category, setCategory] = useQueryParam('category', StringParam);
  // eslint-disable-next-line
  const [subCategory, setSubCategory] = useQueryParam('subCategory', StringParam);

  if (loading) return <CircularProgress className="circular" />;
  if (error || data.product == null) return <Alert severity="error">Sorry we had an issue.</Alert>;

  let productSubCategory = data.product.productSubCategory !== undefined && data.product.productSubCategory.length > 0 ? capitalize.words(data.product.productSubCategory[0]):'';
  let productDescription = data.product.descrition;
  let productDetails = [];
  if (data.product.productCode !== undefined && data.product.productCode !== null)
    productDescription = productDescription + " - (" + data.product.productCode + ")";
  if (data.product.unitSize !== undefined &&  data.product.unitSize !== null)
    productDetails.push({ 
      title: "Unit Size: ",
      label: data.product.unitSize,
      icon: "square_foot"
    });
  if (data.product.unitMeasure !== undefined &&  data.product.unitMeasure !== null)
    productDetails.push({ 
      title: "Unit Measure: ",
      label: data.product.unitMeasure,
      icon: "architecture"
    });
  if (data.product.size !== undefined &&  data.product.size !== null)
    productDetails.push({ 
      title: "Size: ",
      label: data.product.size,
      icon: "design_services"
    });
  if (data.product.availability !== undefined &&  data.product.availability !== null)
    productDetails.push({ 
      title: "Availability: ",
      label: data.product.availability,
      icon: "library_books",
      type: data.product.availability==="Out of Stock" ? "negative":null
    });
  if (data.product.colour !== undefined &&  data.product.colour !== null)
    productDetails.push({ 
      title: "Colour: ",
      label: data.product.colour,
      icon: "pallete"
    });
  if (data.product.discontinued !== undefined && data.product.discontinued !== null &&  data.product.discontinued)
    productDetails.push({ 
      title: "Discontinued: ",
      label: "This product has been discontinued",
      icon: "highlight_off",
      type: "negative"
    });

  return (
    <div style={{ padding: 10 }}>
    <Breadcrumbs aria-label="breadcrumb">
      <Link style={{cursor: "pointer"}} color="inherit" onClick={() => {
                    props.history.push("/");
                    setCategory(data.product.productCategory, 'push');
                  }}>
        {data.product.productCategory}
      </Link>
      <Link style={{cursor: "pointer"}}  color="inherit" onClick={() => {
                    props.history.push("/");
                    setCategory(data.product.productCategory, 'pushIn');
                    setSubCategory(productSubCategory, 'pushIn');
                  }}>
        {productSubCategory} 
      </Link>
    </Breadcrumbs>
    <div
      style={{
        marginBottom: 20,
        marginTop: 10,
        fontSize: 22
      }}
    >
      {data.product.title}
    </div>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} lg={2} style={{minWidth: 260}}>
        <img src={data.product.productImageThumbnails} alt="" width={250} height={250}
          style={{
            border: "1px solid lightgray",
            borderRadius: "5px",
            padding: "10px",
            objectFit: "cover"
          }} />
      </Grid>
      <Grid item xs={12} sm={12} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{fontSize: 16, marginBottom: 15}}>
              <span>Brand:</span> <Chip label={data.product.brand} variant="default" size="small" icon={<BookmarkIcon />} />
            </div>
            <div style={isAuthenticated ? {fontSize: 16}:{fontSize: 16, color: "transparent", textShadow: "0 0 5px rgba(0,0,0,0.5)"}}>
              Price: {isAuthenticated ? merchantPrice ? (formatter.format(data.product.merchantPrice)):(formatter.format(data.product.price)):(formatter.format(99.99))}
            </div>
          </Grid>
          {data.product.popular === true && (
            <Grid item xs={12}>
              <Chip label="Popular Product" size="small" icon={<StarsIcon style={{ color: 'white' }} />} style={{fontSize: 14, backgroundColor: "#228B22", color: "#fff" }}/>
            </Grid>
          )}
          </Grid>
          
            <Grid item xs={12} sm={6} lg={6}>
            {isAuthenticated ? 
              (<TextField
                type="number"
                value={quantity}
                style={{ marginTop: 20, marginBottom: 10, width: 70 }}
                label="Quantity"
                inputProps={{ min: 1, max: 10, step: 1 }}
                onChange={e => {
                  setQuantity(parseInt(e.target.value));
                }}
              />):(<span></span>)
            }
            </Grid>
            
            <Grid item xs={12} sm={6} lg={6}>
              {isAuthenticated ? 
              (<Button
                style={{ width: 170, marginTop: 5 }}
                color="primary"
                variant="outlined"
                onClick={() => {
                  data.product.price = merchantPrice ? data.product.merchantPrice:data.product.price;
                  props.dispatch(showSnackbarMessage({ 
                    message: "The item has been added to your cart",
                    duration: 3000,
                    severity: "info"
                  }));
                  props.dispatch(
                    addItemInCart({
                      ...data.product,
                      quantity: quantity
                    })
                  );
                }}
              >
                Add to Cart <AddShoppingCartIcon style={{ marginLeft: 5 }} />
              </Button>):(<span></span>)
              }
            </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* Product description */}
          <ControlledExpansionPanels header1="Product Description" header2="Shipping" header3="None" text1={productDescription} list={productDetails}/>
        </Grid>
    </Grid>

    <div
      style={{
        marginTop: 20,
        marginBottom: 10,
        fontSize: 22
      }}
    >
      Related Items
    </div>
    <RelatedItems relatableProduct={data.product.productCode} category={data.product.productCategory} subCategory={data.product.productSubCategory}/>
  </div>
  );
}

function RelatedItems(props) {

  const { isAuthenticated } = props;
  let PRODUCT_QUERY = !isAuthenticated ? PRODUCT_LIMITED_SEARCH:PRODUCT_SEARCH;

  let category = props.category;
  let subCategory = props.subCategory;
  let relatableProduct = props.relatableProduct;
  let _subCategory = "";
  let page = 1;
  let itemsPerPage = 6;
  let sortValue = 'lh';
  let query = "";

  if (subCategory !== null && subCategory.length > 0)
    _subCategory = subCategory[0];

  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: { query, category, _subCategory, page, itemsPerPage, sortValue },
  });

  if (loading) return <CircularProgress className="circular" />;
  if (error || data.productSearch === undefined) return <Alert severity="error">Sorry we had an issue.</Alert>;
  if (data.productSearch == null) return <p>...</p>;

  return(
    <Grid container spacing={3}>
    {
      data.productSearch.products.map(product => {
        // check for relatable product (same product)
        if (relatableProduct === product.productCode)
          return null;
        return <Grid item xs={12} sm={6} md={4} lg={2}>
                <Item key={product.id} item={product} />
              </Grid>;
      })
    }
  </Grid>
  )

}

const Details = withRouter(connect(mapStateToProps)(ProductDetails));
export default Details;
