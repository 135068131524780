import React from "react";
import Item from "../Item/Item";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paging from "../Paging/Paging";
import ProductsHeader from "../ProductsHeader/ProductsHeader"
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useQueryParam, StringParam, NumberParam, withDefault } from 'use-query-params';
import Alert from '../CustomizedComponents/Alert'
import { withApollo } from 'react-apollo';

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    loggedInUser: state.loggedInUser
  };
};

const useStyles = theme => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
});

const PRODUCT_SEARCH = gql`
    query ProductSearch($query: String, $category: String, $subCategory: String, $popular: Boolean, $page: Int, $itemsPerPage: Int, $sortValue: String) {
      productSearch(query: $query, category: $category, subCategory: $subCategory, popular: $popular, page: $page, itemsPerPage: $itemsPerPage, sortValue: $sortValue)
      {
        products
        {
          id
          availability
          brand
          descrition
          discontinued
          identifierExists
          popular
          price
          merchantPrice
          productCategory
          productSubCategory
          productCode
          productImageThumbnails
          relevanceScore
          title
          unitMeasure
          unitSize
        }
        page
        nbHits
      }
    }   
`;

const PRODUCT_LIMITED_SEARCH = gql`
    query ProductSearch($query: String, $category: String, $subCategory: String, $popular: Boolean, $page: Int, $itemsPerPage: Int, $sortValue: String) {
      productSearch(query: $query, category: $category, subCategory: $subCategory, popular: $popular, page: $page, itemsPerPage: $itemsPerPage, sortValue: $sortValue)
      {
        products
        {
          id
          availability
          brand
          descrition
          discontinued
          identifierExists
          popular
          productCategory
          productSubCategory
          productCode
          productImageThumbnails
          relevanceScore
          title
          unitMeasure
          unitSize
        }
        page
        nbHits
      }
    }   
`;

const PRODUCT_SEARCH_WITH_PRICE_RANGE = gql`
    query ProductSearchWithPriceRange($query: String, $category: String, $subCategory: String, $popular: Boolean, $page: Int, $itemsPerPage: Int, $sortValue: String, $minPrice: Int, $maxPrice: Int, $merchantPrice: Boolean) {
      productSearchWithPriceRange(query: $query, category: $category, subCategory: $subCategory, popular: $popular, page: $page, itemsPerPage: $itemsPerPage, sortValue: $sortValue,  minPrice: $minPrice, maxPrice: $maxPrice, merchantPrice: $merchantPrice)
      {
        products
        {
          id
          availability
          brand
          descrition
          discontinued
          identifierExists
          popular
          price
          merchantPrice
          productCategory
          productSubCategory
          productCode
          productImageThumbnails
          relevanceScore
          title
          unitMeasure
          unitSize
        }
        page
        nbHits
      }
    }   
`;

function ProductList(props) {

  const {isAuthenticated, loggedInUser } = props;
  const [query] = useQueryParam('term', StringParam);
  const [category] = useQueryParam('category', StringParam);
  const [subCategory] = useQueryParam('subCategory', StringParam);
  const [page] = useQueryParam('page', withDefault(NumberParam, 1));
  const [itemsPerPage] = useQueryParam('itemsPerPage', withDefault(NumberParam, parseInt(process.env.REACT_APP_PRODUCT_ITEMS_PER_PAGE)));
  const [minPrice] = useQueryParam('minPrice', NumberParam);
  const [maxPrice] = useQueryParam('maxPrice', NumberParam);
  const [sortValue] = useQueryParam('sortValue', withDefault(StringParam, "lh"));
  const popular = category ? null:true;
  const merchantPrice = loggedInUser !== null ? loggedInUser.appliedPriceSchedule !== null ? true:false : false;
  let PRODUCT_QUERY = !isAuthenticated ? PRODUCT_LIMITED_SEARCH:minPrice === undefined ?  PRODUCT_SEARCH : PRODUCT_SEARCH_WITH_PRICE_RANGE;

  // graphql query use price range if provided
  const { loading, error, data } = useQuery(PRODUCT_QUERY, 
        {variables: minPrice !== undefined && maxPrice !== undefined ? { query, category, subCategory, popular, page, itemsPerPage, sortValue, minPrice, maxPrice, merchantPrice } : { query, category, subCategory, popular, page, itemsPerPage, sortValue } });

  if (loading) return <CircularProgress className="circular" />;
  if (error || (data.productSearch === undefined && data.productSearchWithPriceRange === undefined)) return <Alert severity="error">Sorry we had an issue.</Alert>;
  
  let _nbHits = 0;
  let _products = [];
  if ((data.productSearch !== undefined && data.productSearch !== null))
  {
    _nbHits = data.productSearch !== undefined ? data.productSearch.nbHits : 0;
    _products = data.productSearch !== undefined ? data.productSearch.products : [];
  }
  else if (data.productSearchWithPriceRange !== undefined && data.productSearchWithPriceRange !== null)
  {
    _nbHits = data.productSearchWithPriceRange !== undefined ? data.productSearchWithPriceRange.nbHits: 0;
    _products = data.productSearchWithPriceRange !== undefined ? data.productSearchWithPriceRange.products: [];
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", marginLeft: 5 }}>
    <ProductsHeader totalItemsCount={_nbHits} />
      <Grid container spacing={3}>
        {_products.map(product => { 
          return <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Item key={product.id} item={product} />
                </Grid>;
          })}

          <Grid item xs={12}>
            <Paging
              totalItemsCount={_nbHits}
            />
          </Grid>
        </Grid>
  </div >
  );
}

const _ProductList = withRouter(withStyles(useStyles)(connect(mapStateToProps)(ProductList)));
export default withApollo(_ProductList);