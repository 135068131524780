import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from "./App";
import configureStore from "./ConfigureStore";
import ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { QueryParamProvider } from 'use-query-params';
import { ApolloLink, concat } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from 'apollo-link-http';

const store = configureStore();

const httpLink = new HttpLink({ uri: process.env.REACT_APP_FUNCTIONS_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: localStorage.getItem('token') ?  `Bearer ${localStorage.getItem('token')}` : '',
    }
  });

  return forward(operation);
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

function Root() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default Root;