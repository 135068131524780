import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  showCartDlg,
  showJoinDlg,
  logoutUser
} from "../../Redux/Actions";
import logoImage from "../../Images/logo-64x64.svg";
import { dataForTheMenu } from "../../Data";
import Person from "@material-ui/icons/PersonOutline";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { fade, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import MoreIcon from '@material-ui/icons/MoreVert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';


const mapStateToProps = state => {
  return {
    nrOfItemsInCard: state.cartItems.length,
    loggedInUser: state.loggedInUser,
    isAuthenticated: state.isAuthenticated,
  };
};

const useStyles = theme => ({
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '28ch',
      },
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  select: {
    color: '#fff',
    marginRight: theme.spacing(1),
  },
  icon: {
    fill: '#fff',
    color: '#fff',
  },
  logoIcon: {
    height: '100%',
    marginRight: theme.spacing(1),
  },
  logoIconMobile: {
    height: 40,
    marginRight: theme.spacing(1),
  },
  iconButton: {
    color: '#fff',
    fill: '#fff',
    '&:hover': {
      fill: '#ffffff5c',
    }
  },
  button: {
    color: '#fff',
    borderColor: '#fff',
    '&:hover': {
      borderColor: '#ffffff5c',
    }
  },
  chipHeader: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
    flexWrap: 'wrap',
    '& > *': {
      //margin: theme.spacing(0.5),
    },
  },
});


function MyToolbar(props) {

  const [searchTerm, setSearchTerm] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMobileEl, setAnchorMobileEl] = React.useState(null);
  // eslint-disable-next-line
  const [categoryFilterValue, setCategoryFilterValue] = React.useState(dataForTheMenu[1].name);

  const { classes, isAuthenticated, loggedInUser } = props;

  return (
    <div className={classes.grow}>
        <Toolbar className={classes.toolbar}>
            <IconButton
              onClick={props.onToggleMenu}
              className={classes.menuButton}
            >
              <MenuIcon size="medium" className={classes.icon}></MenuIcon>
            </IconButton>

            {/* DESKTOP LOGO */}
            <div className={classes.sectionDesktop}>
              <img alt="logo" className={classes.logoIcon} src={logoImage}/>
            </div>

            {/* MOBILE LOGO */}
            <div className={classes.sectionMobile}>
              <img alt="logo" className={classes.logoIconMobile} src={logoImage}/>
            </div>

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                autoFocus
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                }}
                placeholder="Search Products…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    props.history.push(
                      "/?category=" +
                      categoryFilterValue +
                      "&term=" +
                      searchTerm
                    );
                    ev.preventDefault();
                  }
                }}
              />
            </div>

            <div className={classes.grow} />
            
            {/* DESKTOP OPTIONS */}
            <div className={classes.sectionDesktop}>
              {!isAuthenticated ? (
              <Tooltip title="Join" arrow>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => {
                    props.dispatch(showJoinDlg(true));
                  }}
                >
                  Join
                </Button>
              </Tooltip>
              ):(<span></span>)}
              <Tooltip title="Cart" arrow>
                <IconButton
                  aria-label="Cart"
                  onClick={() => {
                    props.dispatch(showCartDlg(true));
                  }}
                >
                  <Badge badgeContent={props.nrOfItemsInCard} color="error">
                    <ShoppingCartIcon className={classes.iconButton}/>
                  </Badge>
                </IconButton>
              </Tooltip>
              {!isAuthenticated ? (
                <Tooltip title="Login" arrow>
                  <IconButton
                      aria-label="login"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={event => {
                        props.history.push("/login");
                      }}
                    >
                    <LockOutlinedIcon className={classes.iconButton}/>
                  </IconButton>
                </Tooltip>
                ) : (
                  <Tooltip title="Logout" arrow>
                    <IconButton
                      aria-label="logout"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={event => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <Person className={classes.iconButton}/>
                    </IconButton>
                  </Tooltip>
                )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                {!isAuthenticated ? 
                (<MenuItem
                  onClick={() => {
                    props.dispatch(showJoinDlg(true));
                  }}
                >
                  Join
                </MenuItem>):
                (<Chip
                  icon={<FaceIcon />}
                  className={classes.chipHeader}
                  label={loggedInUser.firstName !== undefined ? loggedInUser.firstName:"Unknown"} 
                  color="default"
                  variant="outline"
                />)
                }
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    props.history.push("/order");
                  }}
                >
                  Checkout
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.dispatch(logoutUser());
                    setAnchorMobileEl(null);
                    setAnchorEl(null);
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>

            {/* MOBILE OPTIONS */}
            <div className={classes.sectionMobile}>
              <Tooltip title="Menu" arrow>
                <IconButton
                  aria-label="show more"
                  aria-haspopup="true"
                  onClick={event => {
                    setAnchorMobileEl(event.currentTarget);
                  }}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Tooltip>

              {!isAuthenticated ? (
                <Menu
                anchorEl={anchorMobileEl}
                open={Boolean(anchorMobileEl)}
                onClose={() => {
                  setAnchorMobileEl(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    props.history.push("/login");
                    setAnchorMobileEl(null);
                  }}
                >
                  Join
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorMobileEl(null);
                    props.history.push("/order");
                  }}
                >
                  Checkout
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.history.push("/login");
                    setAnchorMobileEl(null);
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
              ) : (
                <Menu
                  anchorEl={anchorMobileEl}
                  open={Boolean(anchorMobileEl)}
                  onClose={() => {
                  setAnchorMobileEl(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorMobileEl(null);
                    props.history.push("/order");
                  }}
                >
                  Checkout
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.dispatch(logoutUser());
                    setAnchorMobileEl(null);
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
              )}
            </div>

        </Toolbar>
      </div>
  );
}

const _MyToolbar = withRouter(connect(mapStateToProps)(MyToolbar));
export default withStyles(useStyles)(_MyToolbar);