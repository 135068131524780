import React, { Component } from "react";
import clsx from 'clsx';
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { loginUser, passwordReset } from "../../Redux/Actions";
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const useStyles = theme => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.primary
  },
  form: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
  },
  textBox: {
    marginTop: theme.spacing(3),
  },
  errorText: {
    color: "#f50057",
    marginTop: 20,
    textAlign: "center"
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    //width: '25ch',
  },
});

const mapStateToProps = state => {
  return {
    isLoggingIn: state.isLoggingIn,
    loginError: state.loginError,
    isAuthenticated: state.isAuthenticated,
    loggedInUser: state.loggedInUser
  };
};
class ConnectedLogin extends Component {
  state = {
    userName: "",
    pass: "",
    showPassword: false,
    openPasswordResetDialog: false,
    email: "",
    emailValid: true
  };

  componentDidMount() {
    this.setState({ userName: "" });
    this.setState({ pass: "" });
    this.setState({ email: "" });
  }

  render() {
    const { classes, loginError, isAuthenticated, isLoggingIn } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
   
    if (isLoggingIn) {
      return (
        <CircularProgress className="circular" />
      );
    }
    
    // If user was authenticated, redirect her to where she came from.
    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}
            variant="outlined" elevation={2}
          >
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <FormControl fullWidth className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-username">User name</InputLabel>
              <Input
                autoFocus
                id="standard-adornment-username"
                value={this.state.userName}
                placeholder="User name"
                onChange={e => {
                  this.setState({ userName: e.target.value });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <PersonIcon className={classes.icon}/>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl fullWidth className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.pass}
                placeholder="Password"
                onChange={e => {
                  this.setState({ pass: e.target.value });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={e => {
                        this.setState({ showPassword: !this.state.showPassword });
                      }}
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Link href="#" 
                onClick={() => {
                  this.state.openPasswordResetDialog = true;
                }} 
                color="primary">
              Forgotten your password?
            </Link>

            {loginError && (
              <Typography component="p" className={classes.errorText}>
                Incorrect email or password.
              </Typography>
            )}
            <Button
              className={classes.button}
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                this.props.dispatch(loginUser(this.state.userName, this.state.pass));
              }}
            >
              Log in
            </Button>
          </Paper>

          {/* Password Reset Dialog */}
          <Dialog open={this.state.openPasswordResetDialog}>
            <DialogTitle id="form-dialog-title">Password Reset</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To request a password reset please enter your email below
              </DialogContentText>
              <TextField
                error={!this.state.emailValid}
                required
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={this.state.email}
                helperText={!this.state.emailValid ? "Please enter your email address":""}
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button                 
                onClick={() => {
                  this.setState({ openPasswordResetDialog: false });
                }} 
                color="primary">
                Cancel
              </Button>
              <Button               
                onClick={() => {
                  if (this.state.email.trim.length < 5)
                  {
                    this.setState({ emailValid: false });
                    return;
                  }
                  this.props.dispatch(passwordReset(this.state.email));
                }}
                color="primary">
                Reset
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
    );
  }
}
const Login = withRouter(connect(mapStateToProps)(ConnectedLogin));
export default withStyles(useStyles)(Login);