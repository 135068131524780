import * as CONSTANTS from "./Constants";
import { myFirebase } from "../Firebase/firebase";
import fetch from 'cross-fetch';

export const addItemInCart = item => ({
  type: CONSTANTS.ADD_ITEM_IN_CART,
  payload: item
});
export const showCartDlg = status => ({
  type: CONSTANTS.SHOW_CART_DLG,
  payload: status
});
export const showJoinDlg = status => ({
  type: CONSTANTS.SHOW_JOIN_DLG,
  payload: status
});
export const deleteCartItem = id => ({
  type: CONSTANTS.DELETE_CART_ITEM,
  payload: id
});
export const toggleMenu = status => ({
  type: CONSTANTS.TOGGLE_MENU,
  payload: null
});
export const updateCartItemQnt = obj => ({
  type: CONSTANTS.UPDATE_CART_ITEM_QUANTITY,
  payload: obj
});
export const setCheckedOutItems = items => ({
  type: CONSTANTS.SET_CHECKEDOUT_ITEMS,
  payload: items
});
export const setLoggedInUser = user => ({
  type: CONSTANTS.SET_LOGGED_IN_USER,
  payload: user
});
export const logout = () => ({
  type: CONSTANTS.LOGOUT,
});
export const requestLogin = () => ({
  type: CONSTANTS.LOGIN_REQUEST,
});
export const recieveLogin = user => ({
  type: CONSTANTS.LOGIN_SUCCESS,
  payload: user
});
export const requestLogout = () => ({
  type: CONSTANTS.LOGOUT_REQUEST,
});
export const recieveLogout = () => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
});
export const loginError = () => ({
  type: CONSTANTS.LOGIN_FAILURE
});
export const logoutError = () => ({
  type: CONSTANTS.LOGOUT_FAILURE
});
export const verifyRequest  = () => ({
  type: CONSTANTS.VERIFY_REQUEST
});
export const verifySuccess  = () => ({
  type: CONSTANTS.VERIFY_SUCCESS
});
export const setGroupCategory = groupCategory => ({
  type: CONSTANTS.SET_GROUP_CATEGORY,
  payload: groupCategory
});
export const setMenuCategory = menuCategory => ({
  type: CONSTANTS.SET_MENU_CATEGORY,
  payload: menuCategory
});
export const setMenuSubCategory = menuSubCategory => ({
  type: CONSTANTS.SET_MENU_SUBCATEGORY,
  payload: menuSubCategory
});
export const showSnackbarMessage = snackbarMessage => ({
  type: CONSTANTS.SHOW_SNACKBAR_MESSAGE,
  payload: snackbarMessage
});

/*                                */
/* Login the user                 */
/*                                */
export const loginUser = (email, password) => dispatch => {
  dispatch(requestLogin());

  // login using SDK
  const loginFirebaseUser = (loginData) => {
    return new Promise((resolve, reject) => {
      const _loginData = loginData;
  
      myFirebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          _loginData.firebase = user;
          resolve(_loginData);
          return null;
        })
        .catch(error => {
          reject(error);
        });
      })
    }

  let loginData = 
  {
    email: email,
    password: password
  };

  loginFirebaseUser(loginData)
    .then(loginData => getFirebaseToken(loginData))
    .then(loginData => loginAllBeesUser(loginData))
    .then(loginData => {

      let user = {
        idToken: loginData.idToken,
        name: loginData.clientRecords[0].fields.Name,
        email: loginData.contactRecords[0].fields.Email,
        firstName: loginData.contactRecords[0].fields["First name"],
        lastName: loginData.contactRecords[0].fields.Surname,
        appliedPriceSchedule: loginData.clientRecords[0].fields["Applied Price Schedule"]
      }

      localStorage.setItem('token', user.idToken);
      dispatch(recieveLogin(user));
    })
    .catch((error) => {
      console.error("loginUser: " + error);
      dispatch(loginError());
    });
};

/*                                */
/* Logout the user                */
/*                                */
export const logoutUser = () => dispatch => {
  dispatch(requestLogout());

  myFirebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.setItem('token', null);
      dispatch(recieveLogout());
    })
    .catch(error => {
      console.error("logoutUser: " + error);
      dispatch(logoutError());
    });
};

/*                                */
/* Verify the users authority     */
/*                                */
export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  dispatch(requestLogin());

  // verify Firebase user
  const verifyFirebaseUser = (loginData) => {
    return new Promise((resolve, reject) => {
      const _loginData = loginData;
  
        myFirebase.auth().onAuthStateChanged(user => {
          if (user !== null) {
            _loginData.firebase = {user: user};
            resolve(_loginData); 
          }
          else
            reject(new Error("User not verified"));        
        })
      })
    }

  // verify
  let loginData = {};

  verifyFirebaseUser(loginData)
  .then(loginData => getFirebaseToken(loginData))
  .then(loginData => loginAllBeesUser(loginData))
  .then(loginData => {
    let user = {
      idToken: loginData.idToken,
      name: loginData.clientRecords[0].fields.Name,
      email: loginData.contactRecords[0].fields.Email,
      firstName: loginData.contactRecords[0].fields["First name"],
      lastName: loginData.contactRecords[0].fields.Surname,
      appliedPriceSchedule: loginData.clientRecords[0].fields["Applied Price Schedule"]
    }
    dispatch(verifySuccess());
    dispatch(recieveLogin(user));
  })
  .catch((error) => {
    console.error("verifyAuth: " + error);
    dispatch(loginError());
  });
};

/*                                */
/* Password reset request         */
/*                                */
export const passwordReset = (emailAddress) => dispatch => {

  myFirebase
    .auth()
    .sendPasswordResetEmail(emailAddress)
    .then(() => {
      dispatch(showSnackbarMessage({ 
        message: "A password reset link has been sent to your email address",
        duration: 6000,
        severity: "success"
      }));
    })
    .catch(error => {
      console.error("passwordReset: " + error);
      dispatch(showSnackbarMessage({ 
        message: "Failure to generate password reset email",
        duration: 6000,
        severity: "error"
      }));
    });
};

/*                                */
/* Generic                        */
/*                                */

// login AllBees user
const loginAllBeesUser = (loginData) => {
  return new Promise((resolve, reject) => {

    fetch(process.env.REACT_APP_FUNCTIONS_LOGINUSER_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(loginData),
    })
    .then(res => res.json())
    .then(
      (_loginData) => {
        resolve(_loginData);
        return null;
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// get Firebase token
const getFirebaseToken = (loginData) => {
  return new Promise((resolve, reject) => {
    const _loginData = loginData;

    myFirebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    .then( idToken => {
      _loginData.idToken = idToken;
      resolve(_loginData);
      return null;
    })
    .catch(error => {
      reject(error);
    });
  })
}