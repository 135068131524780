import React from "react";
import Left from "@material-ui/icons/ChevronLeft";
import Right from "@material-ui/icons/ChevronRight";
import First from "@material-ui/icons/FirstPage";
import Last from "@material-ui/icons/LastPage";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { useQueryParam, NumberParam, withDefault} from 'use-query-params';

function Paging(props) {

  const [itemsPerPage] = useQueryParam('itemsPerPage', withDefault(NumberParam, parseInt(process.env.REACT_APP_PRODUCT_ITEMS_PER_PAGE)));
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  let totalPages = Math.ceil(props.totalItemsCount / itemsPerPage);

  if (!props.totalItemsCount) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <IconButton
        size="small"
        color="primary"
        disabled={page === 1}
        onClick={() => {
          setPage(1, 'pushIn');
        }}
        style={{ marginRight: 10 }}
      >
        <First />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        disabled={page === 1}
        onClick={() => {
          setPage((page - 1), 'pushIn');
        }}
        style={{ marginRight: 10 }}
      >
        <Left />
      </IconButton>
      <Typography variant="body1" data-testid="total-pages">Page {page} of {totalPages}</Typography>
      <IconButton
        size="small"
        color="primary"
        disabled={page >= totalPages.toString()}
        onClick={() => {
          setPage((page + 1) , 'pushIn');
        }}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <Right />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        disabled={page >= totalPages.toString()}
        onClick={() => {
          setPage(totalPages, 'pushIn');
        }}
        style={{ marginRight: 10 }}
      >
        <Last />
      </IconButton>

    </div>
  );
};

const paging = withRouter(Paging);
export default paging