import React from "react";
import "./App.css";
import { makeStyles } from '@material-ui/core/styles';
import MyToolbar from "./Components/MyToolbar/MyToolbar.js";
import ProductList from "./Components/ProductList/ProductList";
import { Switch, Route } from "react-router-dom";
import CartDialog from "./Components/CartDialog/CartDialog";
import JoinDialog from "./Components/JoinDialog/JoinDialog";
import ProductDetails from "./Components/ProductDetails/ProductDetails";
import Order from "./Components/Order/Order";
import Login from "./Components/Login/Login";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Footer from "./Components/Footer/Footer";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Menu from './Components/Menu/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { toggleMenu, showSnackbarMessage } from "./Redux/Actions";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CookieConsent from "react-cookie-consent";
import { realtimeDb } from "./Firebase/firebase";
import Button from '@material-ui/core/Button';

const mapStateToProps = function(state) {
  return { 
      mobileOpen: state.showMenu,
      snackbarMessage: state.snackbarMessage
  };
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#253b6e',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
});

const drawerWidth = 260;
const toolbarHeight = 84;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '85%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#253b6e',
    padding: 10,
    marginBottom: 5,
  },
  appBarShadow: {
    height: (toolbarHeight + 10)
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  main: {
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 10
  },
  content: {
    minHeight: 'calc(100% - ' + toolbarHeight + 'px)',
    overflow: 'hidden'
  },
  top: {
    position: "absolute", 
    top: 0
  }
}));

const buttonStyles = makeStyles((theme) => ({
  zoom: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// handle app bar
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// handle scroll to top
function ScrollTop(props) {
  const { children, window } = props;
  const classes = buttonStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    console.log("Go to top " + anchor.id);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.zoom}>
        {children}
      </div>
    </Zoom>
  );
}

/*                                                                                    */
/*  Main app function.                                                                                  */
/*                                                                                    */
function App(props) {

  const classes = useStyles();
  const { window } = props;
  const [siteRelease, setSiteRelease] = React.useState(null);
  const [reloadAlert, setReloadAlert] = React.useState(false);

  const handleDrawerToggle = () => {
    props.dispatch(toggleMenu());
  };

  const handleSnackbarClose = (event, reason) => {
    props.dispatch(showSnackbarMessage(null))
  };

  const handleReloadApp = (event) => {
    setReloadAlert(false);
    location.reload();
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  React.useEffect(function afterRender() {
    // firebase global event - forceSiteReload
    let _siteRelease = realtimeDb.ref('/public/global/siteRelease');
    _siteRelease.on('value', (snapshot) => {
      const data = snapshot.val();
      if (siteRelease === null)
        setSiteRelease(data);
      else
      {
        if (data !== siteRelease)
          setReloadAlert(true);
      }
    });
  });


  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <CssBaseline />
      <div id="back-to-top-anchor" className={classes.top}/>
     
      {/* RELOAD ALERT */}
      <Snackbar open={reloadAlert} 
              variant="success"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              message="This site has been updated. Click reload"
              action={
                <Button color="inherit" size="small" onClick={handleReloadApp}>
                  Reload
                </Button>
              }>
      </Snackbar>

      {/* GOBAL MESSAGE */}
      <Snackbar open={props.snackbarMessage !== null}
              autoHideDuration={props.snackbarMessage !== null ? props.snackbarMessage.duration:0} 
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
        <Alert severity={props.snackbarMessage !== null ? props.snackbarMessage.severity:"info"} onClose={handleSnackbarClose}>
          {props.snackbarMessage !== null ? props.snackbarMessage.message:""} 
        </Alert>
      </Snackbar>

      <HideOnScroll {...props}>
        <AppBar position="fixed" className={classes.appBar} >
          <MyToolbar onToggleMenu={handleDrawerToggle}/>
        </AppBar>
      </HideOnScroll>

      {/* MOBILE FILTER */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Menu/>
        </Drawer>
      </Hidden>

      {/* DESKTOP MENU */}
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
          paper: classes.drawerPaper,
        }}
        >
          <Toolbar className={classes.appBarShadow}/>
          <Menu/>
        </Drawer>
      </Hidden>

      <div className={classes.main}>
        <Toolbar className={classes.appBarShadow}/>
        <div className={classes.content}>
            <CartDialog />
            <JoinDialog />
            <Switch>
              <Route path="/" exact component={ProductList} />
              <Route path="/details/:id" component={ProductDetails} />
              <Route path="/login" component={Login} />
              <ProtectedRoute path="/order" component={Order} />
              <Route
                component={() => (
                  <div style={{ padding: 20 }}>Page not found</div>
                )}
              />
            </Switch>
        </div>
        <Footer />
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    </div>

    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      cookieName="allBees-consent"
      style={{ background: "#2B373B", zIndex: "99999", fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
      buttonStyle={{borderRadius: 4, fontFamily: "Roboto, Helvetica, Arial, sans-serif", lineHeight: 1.75, textTransform: "uppercase", paddingTop: 5, paddingRight: 15, paddingBottom: 5, paddingLeft: 15 }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
    </CookieConsent>

    </ThemeProvider>
  );
}
const Application = withRouter(connect(mapStateToProps)(App));
export default Application;