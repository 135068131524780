import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const firebaseConfig_UAT = {
  apiKey: "AIzaSyAvX2Anbvw3nrKJvKa-S0JipG6dIiq1mNo",
  authDomain: "allbees-uat-webshop.firebaseapp.com",
  databaseURL: "https://allbees-uat-webshop.firebaseio.com",
  projectId: "allbees-uat-webshop",
  storageBucket: "allbees-uat-webshop.appspot.com",
  messagingSenderId: "744928525372",
  appId: "1:744928525372:web:4a48defac0cd3b9de5d739",
  measurementId: "G-J1SJK6217C"
};

const firebaseConfig_PRD = {
  apiKey: "AIzaSyCC8vSL5QcIyVv03LiwCyLmO9n3DqkzBfI",
  authDomain: "allbees-prd-webshop.firebaseapp.com",
  databaseURL: "https://allbees-prd-webshop-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "allbees-prd-webshop",
  storageBucket: "allbees-prd-webshop.appspot.com",
  messagingSenderId: "1088224063271",
  appId: "1:1088224063271:web:7e69997c69947e6fcc8302",
  measurementId: "G-GCHMQ8G09D"
};

const firebaseConfig = process.env.REACT_APP_DEPLOY_ENV='production' ? firebaseConfig_PRD : firebaseConfig_UAT;

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
const _realtimeDb = myFirebase.database();
export const realtimeDb = _realtimeDb;