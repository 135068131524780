import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PriceDialog from "../PriceDialog/PriceDialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { useQueryParams, useQueryParam, StringParam, NumberParam, BooleanParam, withDefault } from 'use-query-params';

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated
  };
};

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));

function ProductsHeader(props) {

    const classes = useStyles();
    const { isAuthenticated } = props;
  
    // Grab some values from the query string
    const [openPriceDialog, setOpenPriceDialog] = React.useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);  
    const [minPrice] = useQueryParam('minPrice', withDefault(NumberParam, 1));
    const [maxPrice] = useQueryParam('maxPrice', withDefault(NumberParam, 1000));
    const [sortValue, setSortValue] = useQueryParam('sortValue', withDefault(StringParam, "lh"));
    const [query] = useQueryParam('term', StringParam);
    const [category] = useQueryParam('category', StringParam);
    const [subCategory] = useQueryParam('subCategory', StringParam);
    const [usePriceFilter, setUsePriceFilter] = useQueryParam('usePriceFilter', withDefault(BooleanParam, false));
    // eslint-disable-next-line
    const [queryString, setQueryString] = useQueryParams({
      minPrice: NumberParam, 
      maxPrice: NumberParam, 
      page: NumberParam, 
      usePriceFilter: BooleanParam,
      sortValue: StringParam,
    });

    return (
      <div>
        <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, fontSize: 24 }}>
            <div data-testid="category-title">{category ? category : "Popular Products"}</div>
              <span style={{ fontSize: 14, color: "gray", marginTop: 5 }}>
              {props.totalItemsCount +
                " result" +
                (props.totalItemsCount === 1 ? " " : "s ") +
                (query ? "for " : "")}
            </span>
            {query && (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "gray",
                  marginTop: 5
                }}
              >
                {query}
              </span>
            )}
          </div>

          {/* DESKTOP FILTER */}
          {isAuthenticated && props.totalItemsCount > 0 ? (
            <div className={classes.sectionDesktop}>
              <FormControlLabel
                control={
                  <Switch
                    checked={usePriceFilter}
                    onChange={e => {
                      setUsePriceFilter(e.target.checked, 'pushIn');
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Filter by price"
              />
              {usePriceFilter && (
                <Tooltip title="Click to change range" disableFocusListener>
                  <Button
                    variant="outlined"
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        setOpenPriceDialog(true)
                    }}
                  >
                    {"£" + minPrice + " - £" + maxPrice}
                  </Button>
                </Tooltip>
              )}
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Price</InputLabel>
                <Select
                  label="Price"
                  style={{ color: 'rgb(115, 115, 115)' }}
                  value={sortValue}
                  onChange={e => {
                    setSortValue(e.target.value, 'pushIn');
                  }}
                >
                  <MenuItem value={"lh"}>low to high</MenuItem>
                  <MenuItem value={"hl"}>high to low</MenuItem>
                </Select>
              </FormControl>
            </div>
          ):(<div></div>)
          }

            {/* MOBILE FILTER */}
            {isAuthenticated && props.totalItemsCount > 0 ? (
              <div className={classes.sectionMobile}>
                <Fab color="primary" 
                  size="small"
                  aria-label="filter"
                  onClick={() => {
                    setOpenFilterDrawer(true);
                  }}
                >
                  <FilterListIcon/>
                </Fab>
              </div>
            ):(<div></div>)
          }
          </div>

          {/* This is dialog which opens up for setting price filter */}
          <PriceDialog
            open={openPriceDialog}
            min={minPrice}
            max={maxPrice}
            onSave={(min, max) => {
              setOpenPriceDialog(false)
              setQueryString({ term: query, category: category,  subCategory: subCategory, minPrice: min, maxPrice: max, page: 1, usePriceFilter: true, sortValue: sortValue }, 'push');
            }}
            onClose={() => {
              setOpenPriceDialog(false);
            }}
          />


        {/* This is dialog which opens up for mobile filtering*/}
        <SwipeableDrawer
          anchor='right'
          open={openFilterDrawer}
          onClose={() =>
            setOpenFilterDrawer(false)
        }
          onOpen={() => setOpenFilterDrawer(true)}
        >
          <List
            subheader={
                <ListSubheader component="div" style={{display: 'flex', flex: 1, padding: 10}}>
                  <FilterListIcon style={{marginRight: 10}}/>
                  <Typography variant="h6">Filter</Typography>
                </ListSubheader>
            }
          >
            <Divider variant="middle" />
            <ListItem key="price">
            <FormControlLabel
                control={
                <Switch
                  checked={usePriceFilter}
                  onChange={e => {
                    if (e.target.checked === false)
                    {
                      setUsePriceFilter(e.target.checked, 'pushIn');
                    }
                  }}
                  name="priceSwitch"
                  color="primary"
                />
              }
                label="Filter by price"
              />
            </ListItem>
              { (usePriceFilter) && (
                <ListItem key="priceButton">
                  <Tooltip title="Click to change range" disableFocusListener>
                    <Button
                      variant="outlined"
                      style={{ marginRight: 20 }}
                      onClick={() => {
                          setOpenPriceDialog(true);
                      }}
                    >
                      {"£" + minPrice + " - £" + maxPrice}
                    </Button>
                  </Tooltip>
                </ListItem>
              )}
            <ListItem key="order">
              <FormControl variant="outlined">
                <InputLabel>Price</InputLabel>
                <Select
                  label="Price"
                  style={{ color: 'rgb(115, 115, 115)' }}
                  value={sortValue}
                  onChange={e => {
                    setSortValue(e.target.value );
                  }}
                >
                  <MenuItem value={"lh"}>low to high</MenuItem>
                  <MenuItem value={"hl"}>high to low</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>

      </SwipeableDrawer>
      </div>
    );
  }

const Header = withRouter(connect(mapStateToProps)(ProductsHeader));
export default Header;