export const ADD_ITEM_IN_CART = "ADD_ITEM_IN_CART";
export const SHOW_CART_DLG = "SHOW_CART_DLG";
export const SHOW_JOIN_DLG = "SHOW_JOIN_DLG";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const UPDATE_CART_ITEM_QUANTITY = "UPDATE_CART_ITEM_QUANTITY";
export const SET_CHECKEDOUT_ITEMS = "SET_CHECKEDOUT_ITEMS";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUR_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const SET_GROUP_CATEGORY = "SET_GROUP_CATEGORY";
export const SET_MENU_CATEGORY = "SET_MENU_CATEGORY";
export const SET_MENU_SUBCATEGORY = "SET_MENU_SUBCATEGORY";
export const SHOW_SNACKBAR_MESSAGE = "SHOW_SNACKBAR_MESSAGE";