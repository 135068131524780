const hasNumber = value => {
    return new RegExp(/[0-9]/).test(value);
 }
 const hasMixed = value => {
    return new RegExp(/[a-z]/).test(value) &&
             new RegExp(/[A-Z]/).test(value);
 }
 const hasSpecial = value => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
 }
 export const strengthText = count => {
    if (count < 2)
       return 'very weak';
    if (count < 3)
       return 'weak';
    if (count < 4)
       return 'good';
    if (count < 5)
       return 'strong';
    if (count < 6)
       return 'very strong';
 }
 export const strengthIndicator = value => {
    let strengths = 0;
    if (value.length > 5)
       strengths++;
    if (value.length > 7)
       strengths++;
    if (hasNumber(value))
       strengths++;
    if (hasSpecial(value))
       strengths++;
    if (hasMixed(value))
       strengths++;
    return strengths;
 }

 export const validURL = url => {
   let pattern = new RegExp(//'^(https?:\\/\\/)?'+ // protocol
     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
   return !!pattern.test(url);
 }

 export const validateEmail = email => {
   const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
   return !pattern.test(String(email).toLowerCase());
}

export const initiateFirebaseDBListeners = () => {


}