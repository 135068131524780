import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { connect } from "react-redux";
import { addItemInCart } from "../../Redux/Actions";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import FALLBACK_IMAGE from "../../Images/awaiting_image.png";
import Chip from '@material-ui/core/Chip';
import StarsIcon from '@material-ui/icons/Stars';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';

const onMediaFallback = event => event.target.src = FALLBACK_IMAGE;

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    loggedInUser: state.loggedInUser
  };
};

const useStyles = theme => ({
  cardHeader: {
      backgroundColor: 'rgba(210, 214, 214, 0.43)'
    },
  cardContent: {
      overflow: 'hidden',
      whiteSpace: 'nowrap'
  },
  cardTitle: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: '110%',
      fontWeight: 600,
      color: '#253b6e' 
    },
  cardSubheader: {
    fontSize: '100%'
  }
});

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

class ConnectedItem extends Component {
  render() {
    const { classes, isAuthenticated, loggedInUser } = this.props;
    const merchantPrice = loggedInUser !== null ? loggedInUser.appliedPriceSchedule !== null ? true:false : false;

    return (
      <Card
        style={{height: 320, width: '100%', fontFamily: "Roboto"}}
      >
        <CardActionArea
          onClick={() => {
            this.props.history.push("/details/" + this.props.item.id + "/" + this.props.item.title);
          }}
        >
          <CardHeader
          classes={{root: classes.cardHeader, content: classes.cardContent, title: classes.cardTitle, subheader: classes.cardSubheader}}
          title={this.props.item.title}
          subheader={this.props.item.productCategory}
          />
          <CardMedia
            component="img"
            style={{ height: 130, width: "60%", marginLeft: "20%", padding: 5}}
            image={this.props.item.productImageThumbnails}
            onError={onMediaFallback}
          />
          <CardContent style={{ color: "rgb(115, 115, 115)", paddingBottom: 5 }}>
            {this.props.item.popular === true && (
              <div style={{marginTop: -35, textAlign: "right"}}>
                <Chip label="Popular" size="small" icon={<StarsIcon style={{ color: 'white', fontSize: '80%' }} />} style={{marginBottom: 10, backgroundColor: "#228B22", color: "#fff", fontSize: '80%'}}/>
              </div>
            )}
            <Grid item xs={12}>
              <Grid container spacing="1">
                  <Grid item xs={6}>
                    <div style={isAuthenticated ? { margin: 5, fontWeight: 600}:{margin: 5, fontWeight: 600, color: "transparent", textShadow: "0 0 3px rgba(0,0,0,0.5)"}}>
                      {isAuthenticated ? merchantPrice ? (formatter.format(this.props.item.merchantPrice)):(formatter.format(this.props.item.price)):(formatter.format(99.99))}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                  {this.props.item.unitMeasure !== undefined && this.props.item.unitMeasure !== null && (
                    <Chip label={this.props.item.unitSize + ' ' + this.props.item.unitMeasure}  variant="outlined" size="small"  style={{ color: "#253b6e", fontWeight: 600}}/>)}
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions
          style={{ display: "flex", alignItems: "center"}}
        >
          <Button
            size="small"
            style={{ marginRight: 60 }}
            onClick={() => {
              this.props.history.push("/details/" + this.props.item.id + "/" + this.props.item.title);
            }}
            startIcon={<VisibilityIcon />}
          >
            View
          </Button>
          
          {isAuthenticated ? (
            <Tooltip title="Add to cart">
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  this.props.dispatch(
                    addItemInCart({ ...this.props.item, quantity: 1 })
                  );
                }}
                color="primary"
                aria-label="Add to shopping cart"
              >
                <AddShoppingCartIcon size="small" />
              </IconButton>
            </Tooltip>
            ):(<div></div>)
          }

        </CardActions>
      </Card>
    );
  }
}

const Item = withRouter(connect(mapStateToProps)(ConnectedItem));
export default withStyles(useStyles)(Item);
